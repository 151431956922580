export interface LancamentoDTO {
  BloquetoImpresso: boolean;
  Cheque: number;
  Codigo: number;
  CodigoBarrasLido: string;
  CodigoContaFinanceira: string;
  CodigoContraPartida: string;
  CodigoEmpresa: string;
  CodigoHistorico: string;
  CodigoPortador: string;
  CodigoTipoPagamento: string;
  CodigoUnidadeNegocio: string;
  CodigoVinculado: string;
  Complemento: string;
  Contabilidade: string;
  ContabilidadeDescricao: string;
  ContaFinanceira: string;
  ContaFinanceiraDescricao: string;
  ContraPartida: string;
  Correlacao: string;
  Data: Date;
  DataAutorizado: Date;
  DataInclusao: Date;
  DataModificacao: Date;
  DataUltimaLiquidacao: Date;
  DescricaoHistorico: string;
  DescricaoPortador: string;
  DescricaoUnidadeNegocio: string;
  Documento: string;
  Duplicata: string;
  Emissao: Date;
  Historico: string;
  JurosMulta: number;
  LancamentoVinculado: string;
  Nf: string;
  NomeEmpresa: string;
  PagamentoDescricao: string;
  Previsao: boolean;
  RemessaEnviada: boolean;
  Saldo: number;
  Serie: string;
  Sessao: number;
  SituacaoDescricao: string;
  StatusOpenBanking: boolean;
  Tipo: string;
  TipoDescricao: string;
  TipoDocumentoDescricao: string;
  TipoJurosDescricao: string;
  TipoPagamento: string;
  TipoPagamentoDescricao: string;
  ToleranciaJuros: number;
  ToleranciaMulta: number;
  ToleranciaVencimento: number;
  Usuario: string;
  UsuarioCriacao: string;
  UsuarioModificacao: string;
  Valor: number;
  ValorDesconto: number;
  ValorJurosDia: number;
  ValorMulta: number;
  Vencimento: string;
}

export interface RateioDTO {
  id: number;
  ClassificacaoContabil: string;
  ClassificacaoGerencial: string;
  CodigoContaGerencial: string;
  CodigoLancamento: number;
  CodigoReduzidoContabil: number;
  NomeContaContabil: string;
  NomeContaGerencial: string;
  PercentualRateioContabil: number;
  PercentualRateioGerencial: number;
  Valor: number;
  ValorRateioContabil: number;
  ValorRateioGerencial: number;
}

export const initialRateios = {
  id: 0,
  ClassificacaoContabil: '',
  ClassificacaoGerencial: '',
  CodigoContaGerencial: '',
  CodigoLancamento: 0,
  CodigoReduzidoContabil: 1,
  NomeContaContabil: '',
  NomeContaGerencial: '',
  PercentualRateioContabil: 0,
  PercentualRateioGerencial: 0,
  Valor: 0,
  ValorRateioContabil: 0,
  ValorRateioGerencial: 0,
};

export interface DebitarCreditarDTO {
  debitar: string;
  creditar: string;
  contabilDebito: string;
  contabilCredito: string;
  descritivoContabilDebito: string;
  descritivoContabilCredito: string;
  mensagemAuxiliar: string;
}

export interface SomaLancamentosDTO {
  TotalPagar: number;
  TotalReceber: number;
  QtdePagar: number;
  QtdeReceber: number;
  TotalReceberAtrasado: number;
  QtdeReceberAtrasado: number;
  TotalPagarAtrasado: number;
  QtdePagarAtrasado: number;
  QueryPagar: LancamentoDTO[];
  QueryReceber: LancamentoDTO[];
  QueryReceberAtrasado: LancamentoDTO[];
  QueryPagarAtrasado: LancamentoDTO[];
}

export interface PortadorDTO {
  codigo: string;
  nome: string;
  portador: string;
  saldo: string;
  tipoControle: string;
  un: string;
}

export const initialPortadores = {
  codigo: '',
  nome: '',
  portador: '',
  saldo: '',
  tipoControle: '',
  un: '',
};

export interface SaldoPortadoresDTO {
  DataInicial: string;
  DataFinal: string;
  PortadorInicial: string;
  PortadorFinal: string;
}

export interface ModeloDTO {
  Codigo: string;
  CodigoEmpresa: string;
  CodigoPortador: string;
  CodigoUnidadeNegocio: string;
  ContaFinanceira: string;
  Descricao: string;
  ExigirPortador: boolean;
  Historico: string;
  Complemento: string;
  Projeto: string;
  Serie: string;
  SolicitarDocumento: string;
  SolicitarHistorico: boolean;
  SolicitarVencimento: string;
  TipoLancamento: string;
  TipoModelo: string;
  TipoPagamento: string;
  Valor: number;
}

export interface PreLancamentoDTO {
  index: number;
  isChecked: boolean;
  isError: boolean;
  CodigoContaFinanceira: string;
  CodigoEmpresa: string;
  CodigoHistorico: string;
  CodigoModelo: string;
  CodigoPortador: string;
  CodigoPreLancamento: number;
  CodigoUnidadeNegocio: string;
  Complemento: string;
  Documento: string;
  Emissao: Date | string | null;
  Previsao: boolean;
  Projeto: string;
  Tipo: string;
  TooltipMessage: string;
  Valor: number;
  Vencimento: Date | string | null;
}

export interface HistoricoDTO {
  Codigo: string;
  Descricao: string;
}

export const getTipoLancamento = (tipo: string) => {
  switch (tipo) {
    case 'R':
      return 'Receber';
    case 'c':
      return 'c Recebido';
    case 'P':
      return 'Pagar';
    case 'd':
      return 'd Pago';
    case 'E':
      return 'Entrada';
    case 'S':
      return 'Saída';
    default:
      return '';
  }
};
