import axios from 'src/utils/axios';
import axiosDebug from 'src/utils/axiosDebug';
import {
  deleteRequestWithAuthorization,
  errorHandler,
  loginErrorHandler,
  postRequest,
} from 'src/services/requests';
import {
  EsqueceuSuaSenhaDTO,
  LoginContatoDTO,
  LoginDTO,
  RecuperaSenhaDTO,
  SessaoDTO,
} from '../types/dtos/auth';
import { loadAllConfiguracoesSistema } from './configuracoesSistemaService';

const getAccessToken = (): string | null => localStorage.getItem('accessToken');

const setAccessToken = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosDebug.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
    delete axiosDebug.defaults.headers.common.Authorization;
  }
};

const isValidToken = (accessToken: string | null): boolean => {
  if (!accessToken) {
    return false;
  }

  return true;
};

const loginWithCpfCnpjAndEmail = async (
  portal: string,
  cpfCnpj: string,
  email: string
): Promise<LoginContatoDTO[]> =>
  new Promise((resolve, reject) => {
    const dataPost = {
      Portal: portal,
      CpfCnpj: cpfCnpj,
      Email: email,
    };

    axios
      .post('/genericos/ge/logincontato', dataPost)
      .then(response => {
        if (response.data.success) {
          const { data } = response.data;
          resolve(data);
        } else {
          const { messages } = response.data;
          reject(messages[0]);
        }
      })
      .catch(error => {
        const message = loginErrorHandler(error);
        reject(message);
      });
  });

const getDeviceData = async () =>
  new Promise((resolve, reject) => {
    axios
      .get('https://corp.cigam.com.br/gc/f1c.ashx')
      .then(response => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(response.data, 'text/html');

        const deviceData = {
          Ip: doc.documentElement.getElementsByTagName('ip')[0].innerHTML,
          Dns: doc.documentElement.getElementsByTagName('dns')[0].innerHTML,
        };

        resolve(deviceData);
      })
      .catch(error => reject(error));
  });

const loginWithCodeVerification = async (
  portal: string,
  codePerson: string,
  sequence: number,
  codeVerification: string
): Promise<LoginDTO[]> => {
  const deviceData = await getDeviceData();

  return new Promise((resolve, reject) => {
    const dataPost = {
      Portal: portal,
      CodigoPessoa: codePerson,
      Sequencia: sequence,
      CodigoVerificacao: codeVerification,
      DadosDispositivo: deviceData,
    };

    axios
      .post('/genericos/ge/login', dataPost)
      .then(response => {
        if (response.data.success) {
          const { hash, data } = response.data;

          setAccessToken(hash);
          loadAllConfiguracoesSistema();
          resolve(data);
        } else {
          const { messages } = response.data;
          reject(messages[0]);
        }
      })
      .catch(error => {
        const message = loginErrorHandler(error);
        reject(message);
      });
  });
};

const loginWithUserNameAndPassword = async (
  userName: string,
  password: string,
  portal: string,
  codigoVerificacao?: string,
  perguntaSecreta?: number,
  respostaSecreta?: string
): Promise<LoginDTO[]> => {
  const deviceData = await getDeviceData();

  return new Promise((resolve, reject) => {
    const dataPost = {
      NomeUsuario: userName,
      Senha: password,
      DadosDispositivo: deviceData,
      Portal: portal,
      CodigoVerificacao2Etapas: codigoVerificacao,
      PerguntaSecreta: perguntaSecreta,
      RespostaSecreta: respostaSecreta,
    };

    axios
      .post('/genericos/ge/login', dataPost)
      .then(response => {
        if (response.data.success) {
          const { hash, data } = response.data;

          setAccessToken(hash);
          loadAllConfiguracoesSistema();
          resolve(data);
        } else {
          const { messages, data } = response.data;
          const { CodigoMensagem } = data;
          if (
            data &&
            (CodigoMensagem === '440' ||
              CodigoMensagem === '439' ||
              CodigoMensagem === '5848')
          ) {
            resolve(data);
          } else {
            reject(messages[0]);
          }
        }
      })
      .catch(error => {
        const message = loginErrorHandler(error);
        const data = error?.response?.data.data;
        if (
          data &&
          (data.CodigoMensagem === '440' ||
            data.CodigoMensagem === '439' ||
            data.CodigoMensagem === '5848')
        ) {
          localStorage.setItem('user', JSON.stringify(data));
          reject(message);
        } else {
          reject(message);
        }
      });
  });
};

const loginInWithToken = (): Promise<SessaoDTO> =>
  new Promise((resolve, reject) => {
    axios
      .post('/genericos/ge/sessao')
      .then(response => {
        if (response.data.success) {
          const { hash, data, tipoLogin } = response.data;

          setAccessToken(hash);
          loadAllConfiguracoesSistema();
          resolve({ ...data[0], type: tipoLogin });
        } else {
          const messages = errorHandler(response);
          reject(messages);
        }
      })
      .catch(error => {
        const messages = errorHandler(error);
        reject(messages);
      });
  });

const logoutSession = (accessToken: string): Promise<void> =>
  new Promise((resolve, reject) => {
    return deleteRequestWithAuthorization(
      '/genericos/ge/sessao',
      accessToken,
      resolve,
      reject
    );
  });

const logout = (): void => {
  const accessToken = getAccessToken();
  if (accessToken) logoutSession(accessToken);

  setAccessToken(null);
  localStorage.removeItem('configuracoes');
  localStorage.removeItem('direitos');
  localStorage.removeItem('debugger');
  localStorage.removeItem('debugger-status');
  localStorage.removeItem('debugger-path');
  localStorage.removeItem('user');
};

const checkSession = (accessToken: string): Promise<any> =>
  axios
    .post(
      '/genericos/ge/sessao',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      errorHandler(error);
    });

const setAxiosInterceptors = (onLogout: () => void): void => {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        setAccessToken(null);

        if (onLogout) {
          onLogout();
        }
      }

      return Promise.reject(error);
    }
  );
};

const handleAuthentication = async (hash: string | null): Promise<void> => {
  const accessToken = getAccessToken();

  if (isValidToken(accessToken)) {
    setAccessToken(accessToken);
  } else if (hash) {
    const session = await checkSession(hash);

    if (session) {
      setAccessToken(session.hash);
    }
  }
};

const isAuthenticated = (): boolean => !!getAccessToken();

const recuperarSenhaUsuario = (
  data: RecuperaSenhaDTO
): Promise<{ success: boolean; messages: string }> => {
  return new Promise((resolve, reject) => {
    postRequest(
      '/genericos/ge/usuario/RecuperarSenhaUsuario',
      data,
      resolve,
      reject
    );
  });
};

const validarToken = (
  data: RecuperaSenhaDTO
): Promise<{
  success: boolean;
  messages: string[];
  data: string;
}> => {
  return new Promise((resolve, reject) => {
    postRequest('/genericos/ge/usuario/ValidarToken', data, resolve, reject);
  });
};

const validarSenha = (
  data: EsqueceuSuaSenhaDTO
): Promise<{ success: boolean; messages: string }> => {
  return new Promise((resolve, reject) => {
    postRequest(
      '/genericos/ge/usuario/ValidarSenhaUsuario',
      data,
      resolve,
      reject
    );
  });
};

export {
  getAccessToken,
  setAccessToken,
  isValidToken,
  loginWithCpfCnpjAndEmail,
  loginWithCodeVerification,
  loginWithUserNameAndPassword,
  loginInWithToken,
  logoutSession,
  logout,
  checkSession,
  setAxiosInterceptors,
  handleAuthentication,
  isAuthenticated,
  recuperarSenhaUsuario,
  validarToken,
  validarSenha,
};
