import { FC } from 'react';
import {
  Trello as TrelloIcon,
  Calendar as CalendarIcon,
  IconProps,
} from 'react-feather';
import { DashBoardRelatorios as DashBoardRelatoriosIcon } from '@cigam/template/dist/components/Icons/DashBoardRelatorios';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SearchIcon from '@mui/icons-material/Search';
import BallotIcon from '@mui/icons-material/Ballot';
import PollIcon from '@mui/icons-material/Poll';
import PieChartIcon from '@mui/icons-material/PieChart';
import TaskIcon from '@mui/icons-material/Task';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { MODULE } from 'src/config';

interface Item {
  title: string;
  href: string;
  icon?: FC<IconProps> | OverridableComponent<SvgIconTypeMap>;
  direito?: string;
  invisible?: boolean;
  items?: Item[];
}

export interface INavConfig {
  moduleName: string;
  nav: {
    subheader: string;
    items: Item[];
  }[];
}

export const navConfig: INavConfig[] = [
  {
    moduleName: '/portalservicos',
    nav: [
      {
        subheader: 'Gerenciamento de Equipe',
        items: [
          {
            title: 'Kanban',
            href: `${MODULE.portalservicos.location}/Kanban`,
            icon: TrelloIcon,
          },
        ],
      },
    ],
  },
  {
    moduleName: '/portalclienteservicos',
    nav: [
      {
        subheader: 'PORTAL CLIENTE/SERVIÇOS',
        items: [
          {
            title: "Minhas OS's",
            icon: BallotIcon,
            href: `${MODULE.portalclienteservicos.location}/MinhasOrdensServico`,
          },
        ],
      },
    ],
  },
  {
    moduleName: '/portalprojetos',
    nav: [
      {
        subheader: 'Gestão de Projetos',
        items: [
          {
            title: 'Agenda de Técnicos',
            href: `${MODULE.portalprojetos.location}/AgendaTecnicos`,
            icon: CalendarIcon,
          },
        ],
      },
    ],
  },
  {
    moduleName: '/portalindustria',
    nav: [
      {
        subheader: 'Portal Indústria',
        items: [
          {
            title: 'Leitor de produção',
            icon: CenterFocusStrongIcon,
            href: `${MODULE.portalindustria.location}/LeitorProducao`,
          },
          {
            title: 'Movimentos de produção',
            icon: SyncAltIcon,
            href: `${MODULE.portalindustria.location}/MovimentosProducao`,
          },
          {
            title: 'Pesquisa Ordens de Produção',
            icon: SearchIcon,
            href: `${MODULE.portalindustria.location}/PDOrdensProducao`,
            direito: 'MNPCPESMOV',
          },
          {
            title: 'Dashboards/Relatórios',
            icon: DashBoardRelatoriosIcon,
            href: '/',
            items: [
              {
                title: 'Emissão da OP',
                href: `${MODULE.portalindustria.location}/Relatorios/CG06464/Emissão da OP/PCIMOP`,
              },
              {
                title: 'Emissão de Talão',
                href: `${MODULE.portalindustria.location}/Relatorios/CG06465/Emissão de Talão/PCIMTA`,
              },
              {
                title: 'Previsto X Realizado por OP',
                href: `${MODULE.portalindustria.location}/Relatorios/CG06178/Previsto X Realizado por OP/6178`,
              },
              {
                title: 'Previsto x Realizado por Material',
                href: `${MODULE.portalindustria.location}/Relatorios/CG06172/Previsto X Realizado por Material/6172`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    moduleName: '/portalcompras',
    nav: [
      {
        subheader: 'Portal Compras',
        items: [
          {
            title: 'Inteligência de Negócios',
            icon: AssessmentIcon,
            href: `${MODULE.portalcompras.location}/InteligenciaNegocios/69eaf00e-e869-435f-ae81-69423acb0a44`,
          },
          {
            title: 'Notas de Entrada',
            icon: FileOpenIcon,
            href: `${MODULE.portalcompras.location}/PDNotasEntrada`,
            direito: 'COENTRA_P',
          },
          {
            title: 'Ordens de Compras',
            icon: LocalMallIcon,
            href: `${MODULE.portalcompras.location}/PDOrdensCompras`,
            direito: 'COORDEMC_P',
          },
          {
            title: 'Solicitações de Compras',
            icon: AddShoppingCartIcon,
            href: `${MODULE.portalcompras.location}/PDSolicitacoesCompras`,
            direito: 'MNCOMOV',
          },
          {
            title: 'Pré Nota',
            icon: TaskIcon,
            href: `${MODULE.portalcompras.location}/PDPreNota`,
            direito: 'PN312QWT98',
          },
          {
            title: 'Gerenciamento XML',
            icon: CloudSyncIcon,
            href: `${MODULE.portalcompras.location}/PDGerenciamentoXML`,
          },
          {
            title: 'Dashboards/Relatórios',
            icon: DashBoardRelatoriosIcon,
            href: '/',
            items: [
              {
                title: 'Ordens de Compras em aberto',
                href: `${MODULE.portalcompras.location}/Relatorios/CG00958/Ordens de Compras em aberto/958`,
              },
              {
                title: 'Compras por Fornecedor',
                href: `${MODULE.portalcompras.location}/Relatorios/CG02623/Compras por Fornecedor/2623b`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    moduleName: '/portalcliente',
    nav: [
      {
        subheader: 'ÁREA FINANCEIRA',
        items: [
          {
            title: 'Boletos',
            icon: ReceiptIcon,
            href: `${MODULE.portalcliente.location}/Boletos`,
          },
          {
            title: 'Contratos',
            icon: NoteAltIcon,
            href: `${MODULE.portalcliente.location}/Contratos`,
          },
        ],
      },
    ],
  },
  {
    moduleName: '/portalfinanceiro',
    nav: [
      {
        subheader: 'PORTAL FINANCEIRO',
        items: [
          {
            title: 'Visão Geral',
            icon: PieChartIcon,
            href: `${MODULE.portalfinanceiro.location}/Dashboard`,
          },
          {
            title: 'Pesquisar Lançamentos',
            icon: SearchIcon,
            href: `${MODULE.portalfinanceiro.location}/PDLancamentos`,
          },
          {
            title: 'Dashboards/Relatórios',
            icon: DashBoardRelatoriosIcon,
            href: '/',
            items: [
              {
                title: 'Financeiro',
                icon: PollIcon,
                href: `${MODULE.portalfinanceiro.location}/Painel/FD9B5E2E-7978-4989-8FEA-49EB4ED3197F`,
              },
              {
                title: 'Fluxo de Caixa',
                icon: PollIcon,
                href: `${MODULE.portalfinanceiro.location}/Painel/a7207f59-e92f-4993-b26f-2b7462e7204b`,
              },
              {
                title: 'Controle Orçamentário',
                icon: PollIcon,
                href: `${MODULE.portalfinanceiro.location}/Painel/95c87104-2f1f-4fd9-ab4f-82cde11980a6`,
              },
              {
                title: 'DRE Gerencial',
                icon: PollIcon,
                href: `${MODULE.portalfinanceiro.location}/Painel/19fe8434-22c8-4472-a253-6fd00e78c01c`,
              },
              {
                title: 'Contas a Pagar',
                icon: PieChartIcon,
                href: `${MODULE.portalfinanceiro.location}/Relatorios/AA02428/Contas a Pagar/CPAGAR`,
              },
              {
                title: 'Contas a Receber',
                icon: PieChartIcon,
                href: `${MODULE.portalfinanceiro.location}/Relatorios/AA02431/Contas a Receber/CONREC`,
              },
              {
                title: 'Efetivos',
                icon: PieChartIcon,
                href: `${MODULE.portalfinanceiro.location}/Relatorios/AA02436/Efetivos/RELEFET`,
              },
            ],
          },
        ],
      },
    ],
  },
];
