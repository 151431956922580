import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import moment from 'moment';
import { AppThunk } from 'src/store';
import { getConfig } from '@cigam/template/dist/utils/configuracoesSistema';
import {
  DebitarCreditarDTO,
  initialRateios,
  HistoricoDTO,
  LancamentoDTO,
  ModeloDTO,
  RateioDTO,
  SomaLancamentosDTO,
  PreLancamentoDTO,
} from '../types/dtos/lancamentos';

interface LancamentoState {
  lancamento: LancamentoDTO;
  lancamentos: [LancamentoDTO];
  liquidacoes: [LancamentoDTO];
  rateios: RateioDTO[];
  debitarCreditar: DebitarCreditarDTO;
  somaLancamentos: SomaLancamentosDTO;
  variacaoEntradasSaidas: SomaLancamentosDTO;
  modelos: [ModeloDTO];
  historicos: [HistoricoDTO];
  portadores: { Codigo: string; Nome: string }[];
  empresas: { Codigo: string; NomeCompleto: string }[];
  contas: { Codigo: string; Descricao: string }[];
  unidades: { Codigo: string; Nome: string }[];
  projetos: { CodigoProjeto: string; NomeProjeto: string; Descricao: string }[];
}

export const initialLancamento: LancamentoDTO = {
  BloquetoImpresso: false,
  Cheque: 0,
  Codigo: 0,
  CodigoBarrasLido: '',
  CodigoContaFinanceira: '',
  CodigoContraPartida: '',
  CodigoEmpresa: '',
  CodigoHistorico: '',
  CodigoPortador: '',
  CodigoTipoPagamento: '',
  CodigoUnidadeNegocio: '',
  CodigoVinculado: '',
  Complemento: '',
  Contabilidade: '',
  ContabilidadeDescricao: '',
  ContaFinanceira: '',
  ContaFinanceiraDescricao: '',
  ContraPartida: '',
  Correlacao: '',
  Data: new Date(),
  DataAutorizado: new Date(),
  DataInclusao: new Date(),
  DataModificacao: new Date(),
  DataUltimaLiquidacao: new Date(),
  DescricaoHistorico: '',
  DescricaoPortador: '',
  DescricaoUnidadeNegocio: '',
  Documento: '',
  Duplicata: '',
  Emissao: new Date(),
  Historico: '',
  JurosMulta: 0,
  LancamentoVinculado: '',
  Nf: '',
  NomeEmpresa: '',
  PagamentoDescricao: '',
  Previsao: false,
  RemessaEnviada: false,
  Saldo: 0,
  Serie: '',
  Sessao: 0,
  SituacaoDescricao: '',
  StatusOpenBanking: false,
  Tipo: '',
  TipoDescricao: '',
  TipoDocumentoDescricao: '',
  TipoJurosDescricao: '',
  TipoPagamento: '',
  TipoPagamentoDescricao: '',
  ToleranciaJuros: 0,
  ToleranciaMulta: 0,
  ToleranciaVencimento: 0,
  Usuario: '',
  UsuarioCriacao: '',
  UsuarioModificacao: '',
  Valor: 0,
  ValorDesconto: 0,
  ValorJurosDia: 0,
  ValorMulta: 0,
  Vencimento: '',
};

const initialDebitarCreditar = {
  debitar: '',
  creditar: '',
  contabilDebito: '',
  contabilCredito: '',
  descritivoContabilDebito: '',
  descritivoContabilCredito: '',
  mensagemAuxiliar: '',
};

const initialSomaLancamentos = {
  TotalPagar: 0,
  TotalReceber: 0,
  QtdePagar: 0,
  QtdeReceber: 0,
  TotalReceberAtrasado: 0,
  QtdeReceberAtrasado: 0,
  TotalPagarAtrasado: 0,
  QtdePagarAtrasado: 0,
  QueryPagar: [initialLancamento],
  QueryReceber: [initialLancamento],
  QueryReceberAtrasado: [initialLancamento],
  QueryPagarAtrasado: [initialLancamento],
};

export const initialModelo = {
  Codigo: '',
  CodigoEmpresa: '',
  CodigoPortador: '',
  CodigoUnidadeNegocio: '',
  ContaFinanceira: '',
  Descricao: '',
  ExigirPortador: false,
  Historico: '',
  Complemento: '',
  Projeto: '',
  Serie: '',
  SolicitarDocumento: '',
  SolicitarHistorico: false,
  SolicitarVencimento: '',
  TipoLancamento: '',
  TipoModelo: '',
  TipoPagamento: '',
  Valor: 0,
};

export const initialPreLancamento: PreLancamentoDTO = {
  index: 0,
  isChecked: false,
  isError: false,
  CodigoContaFinanceira: '',
  CodigoEmpresa: '',
  CodigoHistorico: '',
  CodigoModelo: '',
  CodigoPortador: '',
  CodigoPreLancamento: 0,
  CodigoUnidadeNegocio: '',
  Complemento: '',
  Documento: '',
  Emissao: null,
  Previsao: false,
  Projeto: '',
  Tipo: '',
  TooltipMessage: '',
  Valor: 0,
  Vencimento: null,
};

const initialState: LancamentoState = {
  lancamento: initialLancamento,
  lancamentos: [initialLancamento],
  liquidacoes: [initialLancamento],
  rateios: [initialRateios],
  debitarCreditar: initialDebitarCreditar,
  somaLancamentos: initialSomaLancamentos,
  variacaoEntradasSaidas: initialSomaLancamentos,
  modelos: [initialModelo],
  historicos: [{ Codigo: '', Descricao: '' }],
  portadores: [{ Codigo: '', Nome: '' }],
  empresas: [{ Codigo: '', NomeCompleto: '' }],
  contas: [{ Codigo: '', Descricao: '' }],
  unidades: [{ Codigo: '', Nome: '' }],
  projetos: [{ CodigoProjeto: '', NomeProjeto: '', Descricao: '' }],
};

const slice = createSlice({
  name: 'lancamentos',
  initialState,
  reducers: {
    updateLancamento(
      state: LancamentoState,
      action: PayloadAction<{
        parametrizacao: string;
        value: string | number | boolean | null | Date;
      }>
    ): void {
      const { parametrizacao, value } = action.payload;

      // @ts-ignore
      state.lancamento[parametrizacao] = value;
    },
    setLancamento(
      state: LancamentoState,
      action: PayloadAction<{
        lancamento: LancamentoDTO;
        statusOpenBanking: boolean;
      }>
    ): void {
      state.lancamento = action.payload.lancamento;
      state.lancamento.StatusOpenBanking = action.payload.statusOpenBanking;
    },
    setLancamentos(
      state: LancamentoState,
      action: PayloadAction<[LancamentoDTO]>
    ): void {
      state.lancamentos = action.payload;
    },
    setLiquidacoes(
      state: LancamentoState,
      action: PayloadAction<[LancamentoDTO]>
    ): void {
      state.liquidacoes = action.payload;
    },
    setRateio: (state, action) => {
      state.rateios.push(action.payload);
    },
    updateRateios(
      state: LancamentoState,
      action: PayloadAction<{
        parametrizacao: string;
        value: string | number | boolean | null | Date;
      }>
    ): void {
      const { parametrizacao, value } = action.payload;

      // @ts-ignore
      state.rateios[parametrizacao] = value;
    },
    setDebitarCreditar(
      state: LancamentoState,
      action: PayloadAction<DebitarCreditarDTO>
    ): void {
      state.debitarCreditar = action.payload;
    },
    setSomaLancamentos(
      state: LancamentoState,
      action: PayloadAction<SomaLancamentosDTO>
    ): void {
      state.somaLancamentos = action.payload;
    },
    setVariacaoEntradasSaidas(
      state: LancamentoState,
      action: PayloadAction<SomaLancamentosDTO>
    ): void {
      state.variacaoEntradasSaidas = action.payload;
    },
    setRateios(
      state: LancamentoState,
      action: PayloadAction<RateioDTO[]>
    ): void {
      state.rateios = action.payload;
    },
    setModelos(
      state: LancamentoState,
      action: PayloadAction<[ModeloDTO]>
    ): void {
      state.modelos = action.payload;
    },
    setHistoricos(
      state: LancamentoState,
      action: PayloadAction<[HistoricoDTO]>
    ): void {
      state.historicos = action.payload;
    },
    setPortadores(
      state: LancamentoState,
      action: PayloadAction<[{ Codigo: string; Nome: string }]>
    ): void {
      state.portadores = action.payload;
    },
    setEmpresas(
      state: LancamentoState,
      action: PayloadAction<[{ Codigo: string; NomeCompleto: string }]>
    ): void {
      state.empresas = action.payload;
    },
    setContas(
      state: LancamentoState,
      action: PayloadAction<[{ Codigo: string; Descricao: string }]>
    ): void {
      state.contas = action.payload;
    },
    setUnidades(
      state: LancamentoState,
      action: PayloadAction<[{ Codigo: string; Nome: string }]>
    ): void {
      state.unidades = action.payload;
    },
    setProjetos(
      state: LancamentoState,
      action: PayloadAction<
        [{ CodigoProjeto: string; NomeProjeto: string; Descricao: string }]
      >
    ): void {
      state.projetos = action.payload;
    },
  },
});

export const { reducer } = slice;
export const {
  updateLancamento,
  setLancamento,
  setLancamentos,
  setLiquidacoes,
  setDebitarCreditar,
  setSomaLancamentos,
  setVariacaoEntradasSaidas,
  setRateio,
  setRateios,
  setModelos,
  setHistoricos,
  setPortadores,
  setEmpresas,
  setContas,
  setUnidades,
  setProjetos,
} = slice.actions;

export const buscarLancamento =
  (codigo: number, statusOpenBanking: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      `financas/gf/lancamentofinanceiro/ListarLancamentos?$filter=Codigo eq ${codigo}`
    );
    dispatch(
      slice.actions.setLancamento({
        lancamento: response.data[0],
        statusOpenBanking,
      })
    );
  };
export const buscarRateios =
  (codigo: number): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      `financas/gf/autorizacaopagamento/BuscarRateio?codigoLancamento=${codigo}`
    );
    const rateios = response.data.map((rateio: RateioDTO, index: number) => {
      return { ...rateio, id: index };
    });
    dispatch(slice.actions.setRateios(rateios));
  };

export const buscarLancamentos =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      'financas/gf/lancamentofinanceiro/ListarLancamentos'
    );
    dispatch(slice.actions.setLancamentos(response.data));
  };

export const buscarLiquidacoes =
  (codigo: number): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      `financas/gf/lancamentofinanceiro/BuscarLiquidacoes?codigoLancamento=${codigo}&$orderby=Codigo asc`
    );
    dispatch(slice.actions.setLiquidacoes(response.data));
  };

export const buscarDebitarCreditar =
  (codigo: number, avaliarPreLancamento?: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      `financas/gf/lancamentofinanceiro/DebitarCreditar?codigoLancamento=${codigo}&avaliarPreLancamento=${avaliarPreLancamento}`
    );
    dispatch(slice.actions.setDebitarCreditar(response.data.data));
  };

export const buscarSomaLancamentos =
  (date: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      `financas/gf/lancamentofinanceiro/BuscarSomaLancamentos${date}`
    );
    dispatch(slice.actions.setSomaLancamentos(response.data));
  };

export const buscarVariacaoEntradasSaidas =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const date = `?dataInicial=${moment().format(
      'YYYY-MM-DD'
    )}&dataFinal=${moment().add(30, 'days').format('YYYY-MM-DD')}`;

    const response = await axios.get(
      `financas/gf/lancamentofinanceiro/BuscarSomaLancamentos${date}`
    );
    dispatch(slice.actions.setVariacaoEntradasSaidas(response.data));
  };

export const buscarModelos =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const config1483 = getConfig(1483);
    const config1484 = getConfig(1484);
    let filtros = ``;

    if (config1483) {
      filtros = `Codigo ge '${config1483}' and `;
    }

    if (config1484) {
      filtros = `Codigo le '${config1484}' and`;
    }

    const response = await axios.get(
      `financas/gf/lancamentofinanceiro/BuscarModelos?$orderby=TipoLancamento desc,Codigo&$filter=${filtros} DisponibilizarWeb eq 1 and TipoModelo eq '2'`
    );
    dispatch(slice.actions.setModelos(response.data));
  };

export const buscarHistoricos =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      'financas/gf/historico/buscar?$filter=Ativo eq true'
    );
    dispatch(slice.actions.setHistoricos(response.data));
  };

export const buscarPortadores =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      'financas/gf/portador/Buscar?$filter=Ativo eq true'
    );
    dispatch(slice.actions.setPortadores(response.data));
  };

export const buscarEmpresas =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      'genericos/ge/pessoa/Buscar?$filter=Ativo eq true'
    );
    dispatch(slice.actions.setEmpresas(response.data));
  };

export const buscarContas =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      'financas/gf/conta/Buscar?$filter=Ativo eq true'
    );
    dispatch(slice.actions.setContas(response.data));
  };

export const buscarUnidades =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      'financas/gf/unidadenegocio/Buscar?$filter=Empresa/Ativo eq true'
    );
    dispatch(slice.actions.setUnidades(response.data));
  };

export const buscarProjetos =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      'bpm/pj/projeto/Buscar?$filter=Ativo eq true'
    );
    dispatch(slice.actions.setProjetos(response.data));
  };
